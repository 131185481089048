import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash-es';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { SelectField } from '@beewise/select-field';
import PropTypes from 'prop-types';
import './SearchSelectField.scss';

const SearchSelectField = ({ options, onSearch }) => {
    const selectSearchRef = useRef(null);
    const [inputValue, setInputValue] = useState('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedOnSearch = useCallback(debounce(onSearch, 500), []);

    const handleSearchChange = value => {
        setInputValue(value);
        debouncedOnSearch({ search: value });
    };

    const handleClearInput = () => {
        setInputValue('');
        debouncedOnSearch();
    };

    useEffect(() => () => debouncedOnSearch.cancel(), [debouncedOnSearch]);

    return (
        <div className="search-container">
            <SelectField
                ref={selectSearchRef}
                options={options}
                onInputChange={handleSearchChange}
                onChange={handleSearchChange}
                placeholder="Search"
                onMenuClose={() => handleSearchChange(inputValue)}
                info={<FontAwesomeIcon icon={faSearch} />}
                isSearchable
                size="small"
                value={inputValue}
                handleClearInput={handleClearInput}
            />
        </div>
    );
};

export default SearchSelectField;

SearchSelectField.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        })
    ).isRequired,
    onSearch: PropTypes.func.isRequired,
};
