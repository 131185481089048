import { FETCH_RANCHES } from '../actionTypes';
import { generateQueryParams } from '../utils';

export const fetchRanches = (params = {}) => {
    const queryParams = generateQueryParams(params);
    const url = queryParams ? `/ranches/v2?${queryParams}` : '/ranches/v2';

    return {
        type: 'FETCH',
        params: {
            type: FETCH_RANCHES.default,
            url,
            failureToastText: 'Loading ranches error',
        },
    };
};
