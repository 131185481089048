import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './Panel.scss';

const Header = ({ children }) => <header className="header">{children}</header>;

const Footer = ({ children }) => <footer className="footer">{children}</footer>;

const SidePanel = ({ children, className }) => <aside className={cn('panel', className)}>{children}</aside>;

Header.propTypes = {
    children: PropTypes.node,
};

Footer.propTypes = {
    children: PropTypes.node,
};

SidePanel.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

SidePanel.Header = Header;
SidePanel.Footer = Footer;
export default SidePanel;
