import { FETCH_RANCHES } from '../actionTypes';
import { populateRanchesDataModel } from '../utils';

const initialState = {
    ranches: [],
};

const brokerDashboard = (state = initialState, action) => {
    const { type, data } = action;

    switch (type) {
        case FETCH_RANCHES.success:
            return {
                ...state,
                ranches: populateRanchesDataModel(data.ranches),
            };
        default:
            return state;
    }
};

export default brokerDashboard;
