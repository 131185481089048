import React from 'react';
import Button from '@beewise/button';
import './Footer.scss';

const Footer = () => (
    <>
        <Button className="btn text-black">Cancel</Button>
        <Button className="btn primary-orange">Save</Button>
    </>
);

export default Footer;
