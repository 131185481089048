import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchRanches } from '../../actions';
import RanchesBlock from './components/RanchesBlock';
import './DashboardV2.scss';

const DashboardV2 = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchRanches());
    }, [dispatch]);

    const handleSearch = useCallback(
        params => {
            dispatch(fetchRanches(params));
        },
        [dispatch]
    );

    return (
        <div className="dashboard-container">
            <div className="dashboard-container-content">
                <RanchesBlock onSearch={handleSearch} />
            </div>
        </div>
    );
};

export default DashboardV2;
