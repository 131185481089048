import React from 'react';
import PropTypes from 'prop-types';
import SearchSelectField from 'components/reusables/SearchSelectField';
import './SearchRanches.scss';

const SearchRanches = ({ onSearch, ranches }) => {
    const ranchOptions = ranches?.map(ranch => ({
        label: ranch.name,
        value: ranch.name,
    }));

    return (
        <div className="ranches-search">
            <SearchSelectField options={ranchOptions} onSearch={onSearch} />
        </div>
    );
};

export default SearchRanches;

SearchRanches.propTypes = {
    onSearch: PropTypes.func,
    ranches: PropTypes.arrayOf(PropTypes.shape()),
};
