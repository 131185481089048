const calculateTotalAmount = (items = [], property) => items.reduce((sum, entity) => sum + (entity[property] || 0), 0);

export const populateRanchesDataModel = ranches =>
    ranches.map(ranch => ({
        ...ranch,
        totalAssignedHives: calculateTotalAmount(ranch.orders, 'hivesAmount'),
        totalPlannedHives: calculateTotalAmount(ranch.locations, 'hivesAmount'),
        totalPlacedHives: calculateTotalAmount(ranch.locations, 'placedHivesAmount'),
    }));

export const generateQueryParams = (params = {}) => {
    const parts = Object.entries(params).reduce((acc, [key, value]) => {
        if (Array.isArray(value)) {
            return [...acc, ...value.map(val => `${key}=${val}`)];
        } else {
            return [...acc, `${key}=${value}`];
        }
    }, []);

    return parts.join('&');
};

export const formatNumber = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getTotalPerformances = ({ ranches }) =>
    ranches.reduce(
        (acc, ranch) => {
            acc.available += Number(ranch.companyHivesAmount) || 0;
            acc.contracted += ranch.requiredHivesAmount || 0;
            acc.assigned += ranch.totalAssignedHives || 0;
            acc.planned += ranch.totalPlannedHives || 0;
            acc.placed += ranch.totalPlacedHives || 0;
            return acc;
        },
        { available: 0, contracted: 0, assigned: 0, planned: 0, placed: 0 }
    );
