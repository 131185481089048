import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ForgotPasswordForm } from '@beewise/auth-form';
import { getUrlParam } from '@beewise/utils';
import { AUTH_FORM_TITLE } from 'components/constants';
import analytics from 'utils/analytics';
import { setPasswordAndUsername as setPasswordAndUsernameAction } from './actions';

const PasswordSetup = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isForgotPassword = getUrlParam('isForgotPassword');
    const [submissionError, setSubmissionError] = useState('');

    const submitChangePassword = data => {
        analytics.fireGeneralEvent({
            action: 'Setting Up Username and Password',
        });
        dispatch(
            setPasswordAndUsernameAction(
                data.password,
                data.username,
                getUrlParam('confirmationToken'),
                () => {
                    navigate('/signin');
                },
                error => {
                    setSubmissionError(error);
                }
            )
        );
    };

    return (
        <ForgotPasswordForm
            onSubmit={submitChangePassword}
            error={submissionError}
            title={AUTH_FORM_TITLE}
            isForgotPassword={isForgotPassword}
        />
    );
};

export default PasswordSetup;
