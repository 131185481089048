import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { getRanches } from 'components/views/BrokerView/selectors';
import AddRanch from './AddRanch';
import SearchRanches from './SearchRanches';
import RanchesList from './RanchesList';
import RanchesPerformanceBlock from './RanchesPerformanceBlock';
import './RanchesBlock.scss';

const RanchesBlock = ({ onSearch }) => {
    const ranches = useSelector(getRanches, arrayOfObjectsShallowEqual);
    return (
        <div className="ranches-container">
            <RanchesPerformanceBlock ranches={ranches} />
            <AddRanch ranches={ranches} />
            <SearchRanches onSearch={onSearch} ranches={ranches} />
            <RanchesList ranches={ranches} />
        </div>
    );
};

export default RanchesBlock;

RanchesBlock.propTypes = {
    onSearch: PropTypes.func,
};
