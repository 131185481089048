import React from 'react';
import PropTypes from 'prop-types';
import { InputFormField, SelectFormField, VALIDATIONS } from '@beewise/hook-form';
import IconButtonV2 from 'components/reusables/IconButtonV2';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';

const TreeField = ({ control, index, field, remove, treeOptions }) => (
    <fieldset className="left-panel-form-row" key={field.id}>
        <SelectFormField
            name={`trees.${index}.tree`}
            control={control}
            label="Tree"
            options={treeOptions}
            className="col-two-3"
        />
        <InputFormField
            name={`trees.${index}.percent`}
            control={control}
            className="col-one-3"
            pattern={VALIDATIONS.NUMBER}
            type="percentage"
        />
        <IconButtonV2 icon={faCircleXmark} onClick={() => remove(index)} className="icon-remove" />
    </fieldset>
);

TreeField.propTypes = {
    control: PropTypes.shape().isRequired,
    index: PropTypes.number.isRequired,
    field: PropTypes.shape().isRequired,
    remove: PropTypes.func.isRequired,
    treeOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default TreeField;
