import React from 'react';
import { loading } from '@beewise/react-utils';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import RanchItem from './RanchItem';
import { FETCH_RANCHES } from '../../../../../actionTypes';
import './RanchesList.scss';

const TOP_HEIGHT = 200;
const ITEM_SIZE = 108;
const DUMMY_RANCH_COUNT = 11;

const RanchListContentLoader = () => (
    <div className="ranches-list">
        <ContentLoader height="100%" width="100%" speed={1} backgroundColor="#EEEEEE" foregroundColor="#BDBDBD">
            {[...Array(DUMMY_RANCH_COUNT)].map((_, index) => {
                const uniqueKey = `ranch-loader-${index}`;
                return (
                    <React.Fragment key={uniqueKey}>
                        <rect x="36" y={index * 60 + 10} rx="3" ry="3" width="20%" height="20" />
                        <rect x="calc(100% - 36px - 60%)" y={index * 60 + 10} rx="3" ry="3" width="60%" height="20" />
                    </React.Fragment>
                );
            })}
        </ContentLoader>
    </div>
);

const RanchesList = ({ ranches }) => (
    <AutoSizer>
        {({ height, width }) => (
            <List
                className="ranches-list"
                itemCount={ranches.length}
                itemSize={ITEM_SIZE}
                height={height - TOP_HEIGHT}
                width={width}
                itemData={{ ranches }}
            >
                {RanchItem}
            </List>
        )}
    </AutoSizer>
);

export default loading(FETCH_RANCHES.default, RanchListContentLoader)(RanchesList);

RanchesList.propTypes = {
    ranches: PropTypes.arrayOf(PropTypes.shape()),
};
