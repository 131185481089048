import { useForm } from 'react-hook-form';

const DEFAULT_VALUES = {
    brokerId: '',
    name: '',
    companyId: '',
    ranchManagerId: '',
    contactIds: [],
    contractedHives: 0,
    area: 0,
    crop: '',
    hiveGrade: '',
    trees: [],
};

const useCustomForm = () => {
    const form = useForm({
        defaultValues: DEFAULT_VALUES,
    });

    return { form };
};

export default useCustomForm;
