export const ROUTE_PATHS = {
    HOME: '/',
    SIGNIN: '/signin',
    SETUP_PASSWORD: '/setup-password',
    RANCH: '/ranch',
    GROWERS: '/growers',
    BEEKEEPERS: '/beekeepers',
    INSTALLERS: '/installers',
    CONFERENCE: '/conf',
    REPORT: '/report',
    DASHBOARD: '/v2/',
    RANCH_EDIT: '/v2/ranch',
    COMPANIES: '/v2/companies',
};
