import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { SIZE_VALUES } from './utils';

const PADDING_HORIZONTAL = 14;

const Label = ({ label, name, active, error, multiline, size, required, iconUsed }) => {
  const transformLabel = {
    '--height': SIZE_VALUES[size],
    '--is-with-icon': iconUsed ? PADDING_HORIZONTAL * 2 : 0
  };
  return label ? (
    <label
      htmlFor={name}
      className={cn('text-field-label', size, {
        'text-field--active': active,
        'text-field--error': error,
        multiline
      })}
      style={transformLabel}
    >
      <span className="text-field-label--item">
        {label}
        {required && ' *'}
      </span>
    </label>
  ) : null;
};

Label.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  active: PropTypes.bool,
  error: PropTypes.bool,
  multiline: PropTypes.bool,
  size: PropTypes.string,
  required: PropTypes.bool,
  iconUsed: PropTypes.bool
};

export default Label;
