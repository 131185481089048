import React from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/reusables/Panel';
import Footer from './Footer';
import RanchForm from './RanchForm';
import './LeftPanel.scss';

const Header = () => <h2>New Ranch</h2>;

const LeftPanel = ({ form }) => (
    <Panel className="left-panel">
        <Panel.Header>
            <Header />
        </Panel.Header>
        <RanchForm form={form} />
        <Panel.Footer>
            <Footer />
        </Panel.Footer>
    </Panel>
);

LeftPanel.propTypes = {
    form: PropTypes.shape().isRequired,
};

export default LeftPanel;
