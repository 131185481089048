import React from 'react';
import { useParams } from 'react-router-dom';
import useCustomForm from './hooks/useCustomForm';
import LeftPanel from './components/LeftPanel';
import Map from './components/Map';

const RanchEdit = () => {
    const { id } = useParams();
    const { form } = useCustomForm(id);
    return (
        <>
            <LeftPanel form={form} />
            <Map />
        </>
    );
};

export default RanchEdit;
