import React from 'react';
import PropTypes from 'prop-types';
import { InputFormField, SelectFormField } from '@beewise/hook-form';
import constants from 'appConstants';
import TreeVarietiesFields from './TreeVarietyField';
import './RanchForm.scss';
import 'Typography.scss';

const { CROP } = constants;

const cropOptions = Object.entries(CROP).map(([key, value]) => ({
    label: value,
    value: key,
}));

const RanchForm = ({ form }) => {
    const { control } = form;

    return (
        <form className="ranch-form">
            <SelectFormField name="brokerId" control={control} label="Broker" required options={[]} />
            <fieldset className="row">
                <InputFormField name="name" control={control} label="Ranch name" required />
                <SelectFormField name="companyId" control={control} label="Company" required options={[]} />
            </fieldset>
            <SelectFormField name="ranchManagerId" control={control} label="Ranch manager" required options={[]} />
            <SelectFormField name="contactIds" control={control} label="Contacts" required options={[]} />
            <fieldset className="row">
                <InputFormField name="contractedHives" control={control} label="Contracted hives" />
                <InputFormField name="area" control={control} label="Acres" />
            </fieldset>
            <fieldset className="row">
                <SelectFormField name="crop" control={control} label="Crop" options={cropOptions} />
                <InputFormField name="hiveGrade" control={control} label="Hive grade" />
            </fieldset>
            <TreeVarietiesFields control={control} />
        </form>
    );
};

RanchForm.propTypes = {
    form: PropTypes.shape().isRequired,
};

export default RanchForm;
