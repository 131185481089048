import React from 'react';
import Button from '@beewise/button';
import PropTypes from 'prop-types';
import getTreeList from 'components/reusables/Form/utils/getTreeList';
import { useWatch, useFieldArray } from 'react-hook-form';
import TreeField from './TreeField';

const mapToOptions = ({ value, text }) => ({ value, label: text });

const TreeVarietiesFields = ({ control }) => {
    const { append, fields, remove } = useFieldArray({
        control,
        name: 'trees',
    });

    const addTreeVariety = () => append({ tree: '', percent: '' });

    const crop = useWatch({ control, name: 'crop' });
    const treeOptions = getTreeList(crop).map(mapToOptions);
    return (
        <>
            {fields.map((field, index) => (
                <TreeField
                    control={control}
                    index={index}
                    field={field}
                    remove={remove}
                    treeOptions={treeOptions}
                    key={field.id}
                />
            ))}
            <Button className="text-blue btn-add" onClick={addTreeVariety}>
                + Add tree variety
            </Button>
        </>
    );
};

TreeVarietiesFields.propTypes = {
    control: PropTypes.shape().isRequired,
};

export default TreeVarietiesFields;
