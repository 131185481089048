import React from 'react';
import PropTypes from 'prop-types';
import { RanchType } from '../types';
import './RanchItem.scss';

const RanchItem = ({ data: { ranches }, index, style }) => {
    const ranch = ranches[index];

    if (!ranch) {
        return;
    }

    const {
        name,
        requiredHivesAmount = 0,
        totalPlannedHives = 0,
        totalAssignedHives = 0,
        totalPlacedHives = 0,
    } = ranch;

    const ranchDetails = [
        { label: 'Contracted:', value: requiredHivesAmount },
        { label: 'Planned:', value: totalPlannedHives },
        { label: 'Assigned:', value: totalAssignedHives },
        { label: 'Placed:', value: totalPlacedHives },
    ];

    return (
        <div style={style} className="ranch-item">
            <div className="ranch-item-content">
                <div className="ranch-item-content-top">{name}</div>
                <div className="ranch-item-content-bottom">
                    {ranchDetails.map(({ label, value }, index) => (
                        <div className="ranch-item-content-bottom-item" key={`${label}-${index}`}>
                            <span>{label}</span> {value}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

RanchItem.propTypes = {
    data: PropTypes.shape({
        ranches: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape(RanchType)), PropTypes.array]),
    }).isRequired,
    index: PropTypes.number.isRequired,
    style: PropTypes.shape({}).isRequired,
};

export default RanchItem;
