import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from '@beewise/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ButtonV2.scss';

const renderIcon = (icon) => {
  if (!icon) return null;
  return typeof icon === 'string' ? (
    <Icon type={icon} className="button-icon" />
  ) : (
    <FontAwesomeIcon icon={icon} className="button-icon" />
  );
};

const ButtonV2Component = forwardRef(
  (
    {
      className,
      children,
      onClick,
      variant = 'text',
      size = 'medium',
      color = 'default',
      disabled = false,
      startIcon,
      endIcon,
      ...props
    },
    ref
  ) => {
    const handleButtonClick = (e) => onClick(e);

    const buttonClass = cx('btn', className, {
      [`btn-${variant}`]: variant,
      [`btn-${size}`]: size,
      [`btn-${color}`]: color,
      'btn-disabled': disabled
    });

    return (
      <button
        className={buttonClass}
        ref={ref}
        onClick={handleButtonClick}
        disabled={disabled}
        {...props}
      >
        {renderIcon(startIcon)}
        {children}
        {renderIcon(endIcon)}
      </button>
    );
  }
);

ButtonV2Component.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.oneOf(['default', 'orange']),
  disabled: PropTypes.bool,
  startIcon: PropTypes.shape(),
  endIcon: PropTypes.shape()
};

export default ButtonV2Component;
